import React, {useEffect} from 'react';
import flyover1Mobile from './flyover1-mobile.jpg';
import flyover1Desktop from './flyover1-desktop.jpg';
import flyover2Mobile from './flyover2-mobile.jpg';
import flyover2Desktop from './flyover2-desktop.jpg';
import flyover3Mobile from './flyover3-mobile.jpg';
import flyover3Desktop from './flyover3-desktop.jpg';
import townMobile from './town-image-mobile.jpg';
import townDesktop from './town-image-desktop.jpg';
import siteplan from './siteplan.png';
import './App.css';

document.addEventListener("DOMContentLoaded", function(){
  setTimeout(function() {
    document.documentElement.classList.add('loaded');
  }, 3000);
});


function App() {

  function handleContactClick(e) {
    e.preventDefault();
    
    var contactarea = document.getElementById('contact');
    contactarea.scrollIntoView({behavior: 'smooth'});
  }

  useEffect(() => {
    var form = document.getElementById("my-contact-form");
    var formMessage = document.getElementById("form-message");
    form.onsubmit = function(event) {
      event.preventDefault();
      if(form.classList.contains('submitted')) {
        return false;
      }
      var formData = new FormData(form);
      var xhr = new XMLHttpRequest();
      xhr.open("POST", form.action, true);
      xhr.send(formData);
      xhr.onload = function(e) {
        
        if (xhr.status === 200) {
          formMessage.innerHTML = "Thank you for your enquiry. We will be in touch soon.";
        } else {
          var response = JSON.parse(xhr.response);
          formMessage.innerHTML = "Error: " + response.error;
        }
        form.classList.add('submitted');
      };

    };

    
  }, []); 
  return (
    <div className="app">
      <header className="app-header">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 312 403.995" style={{enableBackground: 'new 0 0 312 403.995'}}  xmlSpace="preserve">
     <defs>

    </defs>
    <clipPath id="left-to-right-curve">
      <rect className="flipme" x="0" y="178" width="20" height="0" >
          <animate attributeName="width" values="20;158" dur="0.375s" fill="freeze" begin="1.0875s"/>  
          <animate attributeName="height" values="0;250" dur="0.3s" fill="freeze" begin="1.0875s"/>  
      </rect>
      <rect className="mirrorme" x="156" y="0" width="0" height="140" >
          <animate attributeName="width" values="0;156" dur="0.375s" fill="freeze" begin="1.4625s"/>  
          <animate attributeName="height" values="140;250" dur="0.225s" fill="freeze" begin="1.8375s"/>  
      </rect>
  </clipPath>
  <clipPath id="left-to-right">
      <rect x="0" y="224" width="0" height="82" >
          <animate attributeName="width" values="0;404" dur="0.6s" fill="freeze" begin="0.6375s"/>  
      </rect>
  </clipPath>

<path className="stanim1" fill="#1e4070" d="M156,0C69.885,0,0.073,69.896,0.073,156.109c0,24.385,5.589,47.458,15.547,68.016
	c-6.406-16.714-9.922-34.865-9.922-53.839C5.698,87.182,72.99,19.807,156,19.807c83.005,0,150.297,67.375,150.297,150.479
	c0,18.974-3.516,37.125-9.917,53.839c9.953-20.557,15.542-43.63,15.542-68.016C311.917,69.896,242.109,0,156,0"/>

  <path className="stanim1 curveanim" clipPath="url(#left-to-right-curve)" fill="#ffffff" d="M156,0C69.885,0,0.073,69.896,0.073,156.109c0,24.385,5.589,47.458,15.547,68.016
	c-6.406-16.714-9.922-34.865-9.922-53.839C5.698,87.182,72.99,19.807,156,19.807c83.005,0,150.297,67.375,150.297,150.479
	c0,18.974-3.516,37.125-9.917,53.839c9.953-20.557,15.542-43.63,15.542-68.016C311.917,69.896,242.109,0,156,0"/>


<g className="stanimhouseg" transform="scale(0)">
<animateTransform
      attributeName="transform"
      attributeType="XML"
      type="scale"
      from="0"
      to="1"
      dur="0.6s"
      fill="freeze" />
	<path className="stanimhouse" d="M185.781,90.521l30.052,25.318v-14.25h24.146v34.589l48.292,40.672h-48.292v83.974
		c-13.089,0.906-38.151-3.62-48.911-6.885c-10.766-3.26-3.536-1.068-5.286-1.594V90.521z M72.016,229.578v-52.729H23.724
		l48.292-40.672l24.146-20.339l30.047-25.318v145.958C105.901,232.062,87.964,229.583,72.016,229.578z M172.474,117.021h-15.016
		v15.031h15.016V117.021z M154.536,117.021h-15.016v15.031h15.016V117.021z M172.474,134.875h-15.016v15.031h15.016V134.875z
		 M154.536,134.875h-15.016v15.031h15.016V134.875z M156,65.438l26.198,22.068v163.771c-19.031-5.651-36.438-10.396-52.401-14
		V87.505L156,65.438z"/>
</g>
<path className="stanim1" fill="#1e4070" d="M30.599,268.927c69.005-24.906,142.208,36.932,229.667,25.776C156.068,324.557,136.135,259.411,30.599,268.927z
	"/>
  <path className="stanim1 leftrightanim" clipPath="url(#left-to-right)" fill="#ffffff" d="M30.599,268.927c69.005-24.906,142.208,36.932,229.667,25.776C156.068,324.557,136.135,259.411,30.599,268.927z
	"/>

<path className="stanim1" fill="#1e4070" d="M296.359,224.354c0,0-9.656,69.052-105.292,40.052c-95.635-29.005-151.729-37.292-189.432,4.141
	C85.526,210.396,290.948,372.99,296.359,224.354z"/>
<path className="stanim1 leftrightanim" clipPath="url(#left-to-right)" fill="#ffffff" d="M296.359,224.354c0,0-9.656,69.052-105.292,40.052c-95.635-29.005-151.729-37.292-189.432,4.141
	C85.526,210.396,290.948,372.99,296.359,224.354z"/>

<g className="thewords" opacity="0">
<animateTransform
      attributeName="transform"
      attributeType="XML"
      type="scale"
      from="0.8"
      to="1"
      dur="0.5625s"
      begin="2.025s"
      fill="freeze" />
      <animate attributeName="opacity" attributeType="CSS"
    from="0" to="1" begin="2.025s" dur="0.5625s" fill="freeze"/>
<g>
	<g transform="translate(78.718818, 301.017988)">
		<g>
			<path className="stanim2" d="M-44.505,61.454c-4.156,0-7.74-0.917-10.75-2.75c-3.016-1.844-5.349-4.417-7-7.708
				c-1.656-3.292-2.479-7.115-2.479-11.479c0-4.318,0.823-8.125,2.479-11.417c1.651-3.302,3.984-5.88,7-7.729
				c3.01-1.859,6.594-2.792,10.75-2.792c4.844,0,8.797,1.161,11.854,3.479c3.052,2.323,5.005,5.562,5.854,9.729h-5.646
				c-0.641-2.625-1.958-4.734-3.958-6.333c-2-1.594-4.703-2.396-8.104-2.396c-3.031,0-5.677,0.703-7.938,2.104
				c-2.266,1.391-4.016,3.391-5.25,6c-1.224,2.599-1.833,5.719-1.833,9.354c0,3.641,0.609,6.766,1.833,9.375
				c1.234,2.599,2.984,4.599,5.25,6c2.26,1.391,4.906,2.083,7.938,2.083c3.401,0,6.104-0.776,8.104-2.333
				c2-1.552,3.318-3.63,3.958-6.229h5.646c-0.849,4.083-2.802,7.281-5.854,9.583C-35.708,60.303-39.661,61.454-44.505,61.454z"/>
		</g>
	</g>
</g>
  <g>
    <g transform="translate(111.439488, 301.017988)">
      <g>
        <path className="stanim2" d="M-52.202,60.725V18.308h5.104v42.417H-52.202z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(121.982814, 301.017988)">
      <g>
        <path className="stanim2" d="M-38.751,60.725v-38.25h-12.667v-4.167h30.438v4.167h-12.667v38.25H-38.751z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(147.43223, 301.017988)">
      <g>
        <path className="stanim2" d="M-29.663,60.725V44.6l-13.813-26.292h5.771l10.604,21.438l10.604-21.438h5.687L-24.559,44.6v16.125H-29.663z
          "/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(173.745102, 301.017988)">
    </g>
  </g>
  <g>
    <g transform="translate(185.788131, 301.017988)">
      <g>
        <path className="stanim2" d="M-14.628,60.725l-15.938-42.417h5.521l13.396,37.021l13.5-37.021h5.396L-8.69,60.725H-14.628z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(216.145643, 301.017988)">
      <g>
        <path className="stanim2" d="M-17.3,60.725V18.308h5.104v42.417H-17.3z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(226.688969, 301.017988)">
      <g>
        <path className="stanim2" d="M-13.786,60.725V18.308h26.25v4.167H-8.682v14.792h19.333v4.125H-8.682v15.146h21.146v4.188H-13.786z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(252.320163, 301.017988)">
      <g>
        <path className="stanim2" d="M3.487,60.787L-8.138,18.308h5.438l9.167,36.417l10.417-36.417h5.646l10.104,36.417l9.271-36.417h5.458
          L35.487,60.725h-5.813L19.549,25.704L9.195,60.725L3.487,60.787z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(129.347021, 332.5041)">
      <g>
        <path className="stanim2" d="M-35.921,71.22c-2.792-0.109-5.156-0.167-7.083-0.167c-1.323,0-3.604,0.057-6.854,0.167v-0.958
          c1.094,0,1.755-0.323,1.979-0.979c0.219-0.651,0.339-1.693,0.354-3.125V54.866c0-0.776-0.141-1.318-0.417-1.625
          c-0.281-0.302-0.823-0.458-1.625-0.458v-0.938c3.094,0.125,5.531,0.188,7.312,0.188c1.943,0,3.948-0.062,6.021-0.188
          c0,1.323,0.208,2.661,0.625,4.021l-0.896,0.167c-0.333-1.125-0.828-1.844-1.479-2.167c-0.656-0.333-1.87-0.5-3.646-0.5
          l-3.458,0.062v7.042h2.562c1.177,0,1.99-0.094,2.438-0.292c0.458-0.208,0.698-0.667,0.729-1.375l0.854,0.062
          c-0.125,1.115-0.188,2-0.188,2.667c0,0.542,0.031,1.365,0.104,2.458l-0.875,0.021c0-0.927-0.26-1.505-0.771-1.729
          c-0.5-0.234-1.479-0.354-2.938-0.354h-1.917v7.792c0.917,0.099,2.047,0.146,3.396,0.146c1.969,0,3.354-0.172,4.146-0.521
          c0.792-0.344,1.422-1.234,1.896-2.667l0.958,0.333C-35.332,68.585-35.744,69.986-35.921,71.22z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(149.6897, 332.5041)">
      <g>
        <path className="stanim2" d="M-37.327,71.491c-1.698,0-3.333-0.292-4.917-0.875c0.026-0.219,0.042-0.51,0.042-0.875
          c0-1.292-0.307-2.365-0.917-3.229l0.958-0.562c1.083,2.682,2.917,4.021,5.5,4.021c2.776-0.094,4.271-1.312,4.479-3.646
          c0-1.844-1.677-3.208-5.021-4.083c-3.349-0.875-5.021-2.589-5.021-5.146c0.26-3.552,2.354-5.401,6.271-5.542
          c1.208,0,2.719,0.266,4.542,0.792c0,1.365,0.323,2.51,0.979,3.438l-1.062,0.583c-0.615-2.151-2.188-3.25-4.729-3.292
          c-2.458,0.057-3.745,1.135-3.854,3.229c0,1.792,1.688,3.104,5.062,3.938c3.385,0.823,5.083,2.536,5.083,5.146
          C-30.223,69.361-32.692,71.392-37.327,71.491z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(168.752792, 332.5041)">
      <g>
        <path className="stanim2" d="M-24.494,71.22c-1.24-0.068-2.396-0.104-3.479-0.104c-0.932,0-2.073,0.036-3.417,0.104v-0.958
          c0.833,0,1.401-0.188,1.708-0.562c0.318-0.385,0.479-1.562,0.479-3.521v-12.75h-2.688c-1.292,0-2.177,0.182-2.646,0.542
          c-0.458,0.349-0.979,1.042-1.562,2.083l-0.729-0.417c0.594-1.318,0.896-2.677,0.896-4.083c0.5,0.198,2.01,0.292,4.542,0.292
          h7.354c2.333,0,3.714-0.094,4.146-0.292c0,1.557,0.24,2.917,0.729,4.083l-0.896,0.396c-0.365-1.026-0.792-1.714-1.292-2.062
          c-0.49-0.344-1.391-0.526-2.708-0.542h-2.542v12.75c0,1.974,0.13,3.146,0.396,3.521c0.276,0.375,0.844,0.562,1.708,0.562V71.22z"
          />
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(189.852989, 332.5041)">
      <g>
        <path className="stanim2" d="M-11.315,71.22c-1.432-0.068-2.557-0.104-3.375-0.104c-1.016,0-2.188,0.036-3.521,0.104v-0.958
          c0.792,0,1.297-0.068,1.521-0.208c0.219-0.151,0.333-0.484,0.333-1c0-0.26-0.062-0.589-0.188-0.979l-1.354-4.167h-6.812
          c-1.099,2.807-1.646,4.521-1.646,5.146c0,0.458,0.115,0.781,0.354,0.958c0.234,0.167,0.76,0.25,1.583,0.25v0.958
          c-1.307-0.068-2.427-0.104-3.354-0.104c-0.75,0-1.766,0.036-3.042,0.104v-0.958c0.625-0.01,1.125-0.135,1.5-0.375
          c0.375-0.234,0.693-0.646,0.958-1.229l6.542-16.625l2.188-0.667l6.021,17.021c0.302,0.724,0.568,1.219,0.792,1.479
          c0.234,0.266,0.734,0.396,1.5,0.396V71.22z M-18.377,62.47l-2.688-8.167l-3.083,8.167H-18.377z"/>
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(211.557159, 332.5041)">
      <g>
        <path className="stanim2" d="M-10.226,71.22c-1.24-0.068-2.396-0.104-3.479-0.104c-0.932,0-2.073,0.036-3.417,0.104v-0.958
          c0.833,0,1.401-0.188,1.708-0.562c0.318-0.385,0.479-1.562,0.479-3.521v-12.75h-2.687c-1.292,0-2.177,0.182-2.646,0.542
          c-0.458,0.349-0.979,1.042-1.562,2.083l-0.729-0.417c0.594-1.318,0.896-2.677,0.896-4.083c0.5,0.198,2.01,0.292,4.542,0.292
          h7.354c2.333,0,3.714-0.094,4.146-0.292c0,1.557,0.24,2.917,0.729,4.083l-0.896,0.396c-0.365-1.026-0.792-1.714-1.292-2.062
          c-0.49-0.344-1.391-0.526-2.708-0.542h-2.542v12.75c0,1.974,0.13,3.146,0.396,3.521c0.276,0.375,0.844,0.562,1.708,0.562V71.22z"
          />
      </g>
    </g>
  </g>
  <g>
    <g transform="translate(232.657363, 332.5041)">
      <g>
        <path className="stanim2" d="M-1.484,71.22c-2.792-0.109-5.156-0.167-7.083-0.167c-1.323,0-3.604,0.057-6.854,0.167v-0.958
          c1.094,0,1.755-0.323,1.979-0.979c0.219-0.651,0.339-1.693,0.354-3.125V54.866c0-0.776-0.141-1.318-0.417-1.625
          c-0.281-0.302-0.823-0.458-1.625-0.458v-0.938c3.094,0.125,5.531,0.188,7.312,0.188c1.943,0,3.948-0.062,6.021-0.188
          c0,1.323,0.208,2.661,0.625,4.021l-0.896,0.167c-0.333-1.125-0.828-1.844-1.479-2.167c-0.656-0.333-1.87-0.5-3.646-0.5
          l-3.458,0.062v7.042h2.563c1.177,0,1.99-0.094,2.437-0.292c0.458-0.208,0.698-0.667,0.729-1.375l0.854,0.062
          c-0.125,1.115-0.188,2-0.188,2.667c0,0.542,0.031,1.365,0.104,2.458l-0.875,0.021c0-0.927-0.26-1.505-0.771-1.729
          c-0.5-0.234-1.479-0.354-2.938-0.354h-1.917v7.792c0.917,0.099,2.047,0.146,3.396,0.146c1.969,0,3.354-0.172,4.146-0.521
          c0.792-0.344,1.422-1.234,1.896-2.667l0.958,0.333C-0.895,68.585-1.307,69.986-1.484,71.22z"/>
      </g>
    </g>
  </g>
  </g>
</svg>
      </header>

      <section className="video-section">
        <div className="video-wrap"><iframe src="https://player.vimeo.com/video/759770469?h=e3c402104f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="City View Estate"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </section>

      <section className="info-section">
        <h2>Ballarat's Premier Development</h2>
        <p>Superb location, quietest part of Ballarat, 5 minutes to the town centre the Luxury of being right on the doorstep without the hustle and bustle of busy&nbsp;streets.</p>
        <p>Views of Lake Wendouree the City and as far as the Pyrenees Ranges & Waubra and surrounding area. Lots ranging in size from 500m2 to 687m2</p>
        <p>This is the very last opportunity, a rare once in a lifetime chance to acquire land in this magnificent position. A family friendly location, within walking distance to Ballarat North Primary School and just a short 5-minute drive to the Ballarat Train Station and CBD along with easy access to the Freeway to Melbourne not to mention a bus at your door.</p>
        <p><a className="btn contact-btn" href="#contact" onClick={handleContactClick}>Request information</a></p>
      </section>

      

 



      <section className="image-section">
        <picture>
          <source srcset={townDesktop} media="(min-width: 768px)" />
          <img src={townMobile} alt="" />
        </picture>
      </section>

      <section className="siteplan-section">
        <h2>Site plan</h2>
        <img src={siteplan} alt="" />
      </section>

      <section className="image-section-contained">
        <picture >
          <source srcset={flyover1Desktop} media="(min-width: 768px)" />
          <img style={{marginBottom: "20px"}} src={flyover1Mobile} alt="" />
        </picture>
        <picture>
          <source srcset={flyover2Desktop} media="(min-width: 768px)" />
          <img style={{marginBottom: "20px"}} src={flyover2Mobile} alt="" />
        </picture>
        <picture>
          <source srcset={flyover3Desktop} media="(min-width: 768px)" />
          <img src={flyover3Mobile} alt="" />
        </picture>
      </section>

      <section id="contact" className="form-section">
        <h2>Expression of interest</h2>
        <form action="https://usebasin.com/f/d59b4bc4f30f" method="POST" id="my-contact-form">
          <label>
            <span>Name*</span>
            <input type="text" id="name" name="name" require="true" maxLength="120" />
          </label>
          <label>
            <span>Phone no*</span>
            <input type="tel" name="phone" placeholder="" pattern="\d*" maxLength="20" required />
          </label>
          <label className="full-width">
            <span>Email Address*</span>
            <input type="email" id="email" name="email" maxLength="100" require="true" />
          </label>
          <label className="full-width">
            <span>Comments</span>
            <textarea maxLength="1500" name="comments"></textarea>
          </label>
          <button className="btn" type="submit">Submit</button>
        </form>
        <div id="form-message"></div> 
      </section>
    </div>
  );
}

export default App;
